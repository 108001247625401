<template>
  <div>
    <ys-page-list
        ref="table"
        action="/check/ListByOrg"
        :params="params"
        :isEmptySelect="isEmptySelect"
        :showContextMenu="false"
        :multiColumn="false"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="打卡开始" prop="signStartAt">
          <ys-date-picker type="datetime" :disabled="!!this.signStartAt" v-model="params.signStartAt" clearable style="width: 180px" placeholder="打卡开始日期"></ys-date-picker>
        </FormItem>
        <FormItem label="打卡结束" prop="signEndAt">
          <ys-date-picker type="datetime" :disabled="!!this.signEndAt" v-model="params.signEndAt" clearable style="width: 180px" placeholder="打卡结束日期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import {formatDate} from "ys-admin";
import components from '@/mixins/components';

export default {
  name: 'org-component',
  mixins: [pageList, components],
  props: {
    orgId: {type: [Number, String], default: ''},
    insOrgId: {type: [Number, String], default: ''},
    signStartAt: {type: [String], default: null},
    signEndAt: {type: [String], default: null},
    type: {type: [Number, String], default: ''},
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {title: '任务编号', width: 250, key: 'number', align: 'left'},
        {width: 200, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '任务类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '检查单类型'}},
        {width: 120, title: '工程类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 200, title: '施工单位', key: 'contractUnit', align: 'left'},
        {width: 120, title: '服务时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ],
      params: {
        insOrgId: '',
        orgId: '',
        signStartAt: '',
        signEndAt: '',
        isTopIns: false,
        keyword: '',
      },
    }
  },
  computed: {},
  mounted() {
    this.params.signStartAt = new Date(formatDate(this.signStartAt, 'yyyy/MM/dd'));
    this.params.signEndAt = new Date(new Date(formatDate(this.signStartAt, 'yyyy/MM/dd')).getTime() + 24 * 3600 * 1000 - 1);
    this.params.insOrgId = this.insOrgId;
    this.params.orgId = this.orgId;
    this.params.type = this.type;
    this.$nextTick(() => {
      this.getList();
    })
  },
  methods: {}
}
</script>
