<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="项目名称" prop="checkId">
        <Input v-model="form.projectName" disabled placeholder="服务任务">
          <Button slot="append" icon="ios-search" @click="checkFlag=true"></Button>
        </Input>
      </FormItem>
      <FormItem label="保险机构" prop="insName">
        <Input v-model="form.insName" disabled placeholder="保险机构"> </Input>
      </FormItem>
      <FormItem label="配合情况" prop="enterpriseAccept" style="width: 100%">
        <Input v-model="form.enterpriseAccept" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows:2,maxRows: 5}" placeholder="配合情况"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('brief/check/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="checkFlag" :width="1200" title="选择服务任务">
      <OrgCheck :orgId="parent.serOrgId" :signStartAt="parent.date" :signEndAt="parent.date" :insOrgId="parent.insOrgId" @on-ok="getCheckData"></OrgCheck>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import OrgCheck from "_c/check";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {OrgCheck},
  data() {
    return {
      checkFlag: false,
      ruleValidate: {
        enterpriseAccept: [
          {required: true, message: '请填写配合情况', trigger: 'blur'}
        ],
      },
      form: {
        briefId: '',
        insName: '',
        projectName: '',
        checkId: '',
        enterpriseAccept: '',
      },
    }
  },
  computed: {
    parent: function () {
      return this.midData.length === 1 ? this.midData[0] : {};
    },
  },
  mounted() {
    if (this.modData.length === 1) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.modData[0][item]
      });
    }else{
      this.form.briefId = this.parentID;
    }
  },
  methods: {
    getCheckData(data) {
      this.form.checkId = data[0].id;
      this.form.insName = data[0].insName;
      this.form.projectName = data[0].projectName;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
